






























import {Component, Vue} from 'vue-property-decorator';
import TopModels from '@/components/TopModels.vue';
import {vxm} from '@/store';
import Loader from '@/components/Loader.vue';
import {PhotoInterface} from '@/types/photoInterface';
import {PhotosSearchParamsInterface} from '@/types/PhotosSearchParamsInterface';
import PostCardNew from '@/components/PostCardNew.vue';

@Component({
  components: {Loader, TopModels, PostCardNew},
})
export default class PicsNew extends Vue {
  isRequestSend = false;
  isMobile = false;
  totalPics = 0;
  viewType = 'grid';
  pics: PhotoInterface[] = [];
  params: PhotosSearchParamsInterface = {
    page: 1,
    take: 15,
    status: 'active',
    isForSale: true,
    isPurchased: true,
  };

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  mounted() {
    this.getPhotos();
    // window.addEventListener('scroll', this.handleScroll);
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.onResize);
  }

  handleScroll() {
    // const bottomOfWindow = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
    if (!this.isRequestSend && this.totalPics > this.pics.length) {
      this.getPhotos();
    }
  }

  getPhotos() {
    this.isRequestSend = true;

    vxm.fan
      .getPhotosSearch(this.params)
      .then((res) => {
        this.totalPics = res.data.count;
        this.pics.push(...res.data.items);
        (this.params.page as number)++;
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.isRequestSend = false;
      });
  }

  onResize() {
    this.isMobile = window.innerWidth <= 1023;
  }
}
